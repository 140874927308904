import * as React from "react";
import RModal from "react-modal";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { css } from "emotion";
import theme from "../themes/theme";
import "./Modal.css";

const baseStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: 4,
    padding: 20,
    // So it doesn't look awkward when the only content is an activity indicator.
    minHeight: 220,
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column"
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: theme.zIndex.modalOverlay
  }
};

type Props = {
  isOpen?: boolean;
  onRequestClose: () => void;
  height?: number;
  width?: number | string;
  children?: React.ReactNode;
};

const Modal = (props: Props) => {
  const { onRequestClose, height, width = 650, children, ...rest } = props;
  return (
    <RModal
      style={{
        ...baseStyle,
        ...{ content: { ...baseStyle.content, ...{ width, height } } }
      }}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        })}
      >
        <IconButton onClick={onRequestClose} aria-label="Close">
          <ClearIcon />
        </IconButton>
      </div>
      {children}
    </RModal>
  );
};

export default Modal;
