import * as React from "react";
import { css } from "emotion";
import computeFontSizeAndLineHeight from "./computeFontSizeAndLineHeight";
import theme from "../themes/theme";
import { Px, Percent } from "../../types";

type TxtSize = -2 | -1 | 0 | 1 | 2 | 3 | 4;

type TxtTransform = "uppercase" | "capitalize";

type TxtProps = {
  align?: "start" | "end" | "left" | "right" | "center" | "justify";
  bold?: boolean;
  italic?: boolean;
  color?: string;
  decoration?: "none" | "underline" | "line-through";
  fontFamily?: string;
  size?: TxtSize;
  transform?: TxtTransform;
  numberOfLines?: 1;
  paddingTop?: Px;
  paddingBottom?: Px;
  paddingLeft?: Px;
  paddingRight?: Px;
  marginTop?: Px;
  marginBottom?: Px;
  marginLeft?: Px;
  marginRight?: Px;
  maxWidth?: Px | Percent;
  title?: string;
  children: string;
};

const yogaDefaults = {
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  alignContent: "flex-start",
  flexShrink: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopStyle: "solid",
  borderBottomStyle: "solid",
  borderLeftStyle: "solid",
  borderRightStyle: "solid",
  borderTopColor: "black",
  borderBottomColor: "black",
  borderLeftColor: "black",
  borderRightColor: "black",
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 0
};

const emulateReactNative = {
  display: "inline",
  // https://github.com/Microsoft/reactxp/blob/328a54affdd573aa99b348e5b60e65e3d4ba57a3/src/web/Text.tsx#L24
  whiteSpace: "pre-wrap",
  overflowWrap: "break-word",
  msHyphens: "auto"
};

const singleLineStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%"
};

class Txt extends React.Component<TxtProps> {
  render() {
    const {
      align,
      bold,
      color = theme.text.color,
      decoration,
      fontFamily = theme.text.fontFamily,
      italic,
      size = 0,
      transform,
      numberOfLines,
      title,
      maxWidth,
      children,
      ...restProps
    } = this.props;

    const { fontSize, lineHeight } = computeFontSizeAndLineHeight(size);

    const style = {
      ...yogaDefaults,
      ...emulateReactNative,
      color,
      fontFamily,
      fontSize,
      lineHeight: lineHeight + "px",
      ...(align ? { textAlign: align } : null),
      ...(bold ? { fontWeight: "600" } : null),
      ...(decoration ? { textDecoration: decoration } : null),
      ...(italic ? { fontStyle: "italic" } : null),
      ...(transform ? { textTransform: transform } : null),
      ...(numberOfLines === 1 ? singleLineStyle : null),
      ...(maxWidth != null ? { maxWidth } : null)
    };

    let _children = children;
    if (typeof children === "string" && transform === "capitalize") {
      // Ensure `capitalize` works if the input is all caps.
      _children = children.toLowerCase();
    }

    return (
      <div
        title={title}
        className={css(
          // @ts-ignore
          { ...restProps, ...style }
        )}
      >
        {_children}
      </div>
    );
  }
}

export default Txt;
