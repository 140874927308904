import graphql from "babel-plugin-relay/macro";
import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./NewSubGroup").then(module => cb(null, module.default));
  },
  path: "groups/:groupUuid/sub-groups/new",
  title: "New Sub Group",
  query: graphql`
    query route_NewSubGroup_Query($groupUuid: ID!) {
      group(uuid: $groupUuid) {
        ...NewSubGroup_group
      }
      groupList {
        ...NewSubGroup_groupList
      }
    }
  `,
  prepareVariables: props => ({
    groupUuid: props.params.groupUuid
  })
};

export const newSubGroupLocation: RouterToFactory = groupUuid => ({
  pathname: `groups/${groupUuid}/sub-groups/new`
});
