import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const staffRoute: Route = {
  getComponent: (_, cb) => {
    import("./Staff").then(module => cb(null, module.default));
  },
  path: "/staff/:uuid",
  title: "Staff",
  query: graphql`
    query route_staff_Query($uuid: ID!, $count: Int) {
      user(uuid: $uuid) {
        ...Staff_user
      }
    }
  `,
  prepareVariables: props => ({
    uuid: props.params.uuid,
    count: 1000
  })
};

export const staffLocation = (uuid: string) => ({
  pathname: `/staff/${uuid}`
});
