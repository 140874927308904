import * as React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link } from "react-router";
import { css } from "emotion";
import getUserUuid from "../../../util/getUserUuid";
import { isNotNullOrUndefined } from "../../../util/isNotNullOrUndefined";
import { RelayEnvironmentContext } from "../../../RelayEnvironmentContext";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../themes/theme";
import { facilityLocation } from "../../screens/Facility/route";
import { FacilityDropdown_Query } from "../../../__generated__/FacilityDropdown_Query.graphql";

const ID = "f-menu";

export default function FacilityDropdown() {
  const { relay_environment } = React.useContext(RelayEnvironmentContext);
  const [isOpen, set_isOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement>(null);

  const handleToggle = () => {
    set_isOpen(!isOpen);
  };

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    set_isOpen(false);
  };

  return (
    <QueryRenderer<FacilityDropdown_Query>
      environment={relay_environment}
      query={graphql`
        query FacilityDropdown_Query($where: UserFacilityWhereInput!) {
          userFacilityList(where: $where) {
            facility {
              uuid
              name
              logoImageLocation
            }
          }
        }
      `}
      variables={{ where: { userUuid: getUserUuid() } }}
      render={rs => {
        const facilities =
          rs.props &&
          rs.props.userFacilityList &&
          rs.props.userFacilityList
            .map(x => x && x.facility)
            .filter(isNotNullOrUndefined);

        if (!facilities || facilities.length === 0) {
          return null;
        }
        return (
          <>
            <Button
              buttonRef={anchorEl}
              aria-owns={isOpen ? ID : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disableRipple={true}
              style={{
                backgroundColor: isOpen
                  ? theme.header.color
                  : theme.header.backgroundColor,
                color: isOpen
                  ? theme.header.backgroundColor
                  : theme.header.color,
                fontWeight: 600,
                textTransform: "none",
                paddingLeft: 8,
                paddingRight: 8,
                flexShrink: 0
              }}
            >
              Entities
              <ExpandMoreIcon
                style={{
                  color: isOpen
                    ? theme.header.backgroundColor
                    : theme.header.color,
                  marginLeft: 2
                }}
              />
            </Button>
            <Popper
              open={isOpen}
              anchorEl={anchorEl.current}
              transition={true}
              disablePortal={true}
              placement="bottom-start"
            >
              {({ TransitionProps }) => (
                // @ts-ignore
                <Grow {...TransitionProps} id={ID} style={{ marginTop: 5 }}>
                  <Paper style={{ maxHeight: 48 * 10, overflowY: "scroll" }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        {facilities.map(x => (
                          // @ts-ignore
                          <MenuItem
                            onClick={handleClose}
                            component={Link}
                            to={facilityLocation(x.uuid)}
                            className={css({ textDecoration: "none" })}
                          >
                            {x.logoImageLocation ? (
                              <Avatar
                                src={x.logoImageLocation}
                                alt={x.name || undefined}
                              />
                            ) : (
                              <Avatar>
                                {(x.name && x.name.slice(0, 1)) || " "}
                              </Avatar>
                            )}

                            <div className={css({ width: 20 })} />
                            {x.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        );
      }}
    />
  );
}
