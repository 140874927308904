import * as React from "react";
import { css } from "emotion";
import { isAuthenticated } from "../util/auth";
import { setDocumentTitle } from "../util/documentTitle";
import { getNavWidth } from "../misc/nav";
import { dimensions } from "../constants";
import Vw from "./lib/Vw";
import Txt from "./lib/Txt";
import H1 from "./lab/H1";
import Modal from "./lib/Modal";
import Header from "./chrome/header/Header";
import HeaderChildren from "./chrome/header/HeaderChildren";
import "./App.css";

type Props = {
  router: any;
  location: any;
  params: any;
  routes: any;
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export default class App extends React.Component<Props, State> {
  previousChildren: React.ReactNode | null | undefined;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    // This bind is intentional. Class property doesn't work.
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  // componentDidMount() {
  //   window.onerror = (msg, url, lineNo, columnNo, error) => {
  //     // POST to gateway to log error.
  //   };
  // }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.key !== this.props.location.key) {
      const title =
        nextProps.routes &&
        last(nextProps.routes) &&
        last(nextProps.routes).title;
      if (title) {
        setDocumentTitle(title);
      }
    }
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      nextProps.location.state.isModal === true &&
      // Don't set previousChildren if isModal was true previously.
      // This prevents the background from changing if we route in a
      // modal.
      (!this.props.location.state || !this.props.location.state.isModal)
    ) {
      this.previousChildren = this.props.children;
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  handleModalClose = () => {
    if (this.props.location.state && this.props.location.state.returnTo) {
      const returnTo = this.props.location.state.returnTo;
      this.props.location.state.returnTo = undefined;
      this.props.router.push(returnTo);
    } else {
      this.props.router.goBack();
    }
  };

  render() {
    const isModal =
      this.props.location.state && this.props.location.state.isModal;

    return (
      <div className={css({ margin: "0 auto" })}>
        {isModal ? (
          <Modal isOpen={true} onRequestClose={this.handleModalClose}>
            {this.props.children}
          </Modal>
        ) : null}
        <Header>{isAuthenticated() ? <HeaderChildren /> : null}</Header>
        <Vw
          styles={{
            flexDirection: "row",
            paddingTop: isAuthenticated() ? dimensions.header.height : 0
          }}
        >
          <div
            className={css([
              {
                flex: "1 0 auto",
                minHeight: `calc(100vh - ${dimensions.header.height}px)`
              },

              getNavWidth() === 0 &&
                // TODO
                window.location.pathname !== "/marketplace" && { padding: 20 }
            ])}
          >
            {this.state.hasError ? (
              <Vw
                styles={{
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  height: "100%"
                }}
              >
                <H1 styles={{ fontSize: 36, paddingBottom: 32 }}>Oops...</H1>
                <Txt>
                  Something went wrong. Please refresh the page and try again.
                </Txt>
              </Vw>
            ) : isModal ? (
              this.previousChildren
            ) : (
              this.props.children
            )}
          </div>
        </Vw>
      </div>
    );
  }
}

function last(xs) {
  return xs[xs.length - 1];
}
