import * as React from "react";
import { css } from "emotion";
import { Px, Percent } from "../../types";

type Size = Px | Percent;

type Color = string;

type BorderStyle = "solid" | "underline";

// https://facebook.github.io/react-native/docs/layout-props.html
// We don't include text-related things like lineHeight. Use Text
// component and specify there instead.
export type BoxStyles = {
  position?: "relative" | "absolute";
  flex?: 0 | 1;
  flexDirection?: "column" | "row" | "row-reverse" | "column";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around";
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "stretch"
    | "space-between"
    | "space-around";
  alignSelf?:
    | "auto"
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline";
  // Disallow 'wrap-reverse' since Yoga doesn't support it.
  flexWrap?: "nowrap" | "wrap";
  flexGrow?: 0 | 1 | 2;
  flexShrink?: 0 | 1 | 2;
  flexBasis?: Size;

  width?: Size;
  height?: Size;
  minWidth?: Size;
  maxWidth?: Size;
  minHeight?: Size;
  maxHeight?: Size;

  top?: Size;
  bottom?: Size;
  left?: Size;
  right?: Size;

  margin?: Size;
  marginTop?: Size;
  marginBottom?: Size;
  marginLeft?: Size;
  marginRight?: Size;

  padding?: Size;
  paddingTop?: Size;
  paddingBottom?: Size;
  paddingLeft?: Size;
  paddingRight?: Size;

  borderWidth?: Size;
  borderTopWidth?: Size;
  borderBottomWidth?: Size;
  borderLeftWidth?: Size;
  borderRightWidth?: Size;

  borderRadius?: Size;
  borderTopLeftRadius?: Size;
  borderTopRightRadius?: Size;
  borderBottomLeftRadius?: Size;
  borderBottomRightRadius?: Size;

  borderColor?: Color;
  borderTopColor?: Color;
  borderBottomColor?: Color;
  borderLeftColor?: Color;
  borderRightColor?: Color;

  borderStyle?: BorderStyle;
  borderTopStyle?: BorderStyle;
  borderBottomStyle?: BorderStyle;
  borderLeftStyle?: BorderStyle;
  borderRightStyle?: BorderStyle;

  backgroundColor?: Color;

  opacity?: 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;

  zIndex?: number;

  boxShadow?: string;

  color?: string;
  fontSize?: number;
  fontWeight?: number;
  overflowY?: string;
};

// Web-only styles. Use sparingly. The existence of this means that Box
// should only be used internally, not in screens.
type _BoxStyles = {
  width?: Size;
  position?: "fixed";
  cursor?: "pointer" | "default";
  pointerEvents?: "none";
  userSelect?: "none";
  outline?: "none";
  boxShadow?: string;
  backgroundPosition?: Percent;
  backgroundImage?: string;
  backgroundRepeat?: "repeat" | "no-repeat";
  backgroundSize?: "cover" | "contain";
  objectFit?: "cover" | "fill" | "contain" | "scale-down" | "none";
  backgroundClip?: "padding-box";
  transition?: string;
  transform?: string;
  transformOrigin?: string;
  borderSpacing?: Size;
  borderCollapse?: "collapse";
  overflowY?: "hidden";
  filter?: string;
  resize?: "none" | "both" | "horizontal" | "vertical";

  // Text styles for elements that cannot use Text children,
  // e.g. `input`s.
  color?: string;
  fontFamily?: "inherit" | string;
  fontSize?: number | "inherit";
  lineHeight?: number | string;

  "-webkit-appearance"?: "none";
  "::placeholder"?: BoxStyles;
  ":first-child"?: BoxStyles;
  ":last-child"?: BoxStyles;
  ":hover"?: BoxStyles;
  ":active"?: BoxStyles;
  ":focus"?: BoxStyles;
};

export type BoxChildren = React.ReactNode;

// type BoxProps = {
//   styles?: BoxStyles;
//   _styles?: _BoxStyles;
//   as?: React.ElementType;
//   children?: BoxChildren;
// };
type BoxProps = any;

// https://facebook.github.io/yoga/docs/learn-more/
// https://jsfiddle.net/emilsjolander/jckmwztt/
const yogaDefaults = {
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  alignContent: "flex-start",
  flexShrink: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopStyle: "solid",
  borderBottomStyle: "solid",
  borderLeftStyle: "solid",
  borderRightStyle: "solid",
  borderTopColor: "black",
  borderBottomColor: "black",
  borderLeftColor: "black",
  borderRightColor: "black",
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 0
};

class Box extends React.Component<BoxProps> {
  render() {
    const { as = "div", styles, _styles, children, ...restProps } = this.props;
    // JSX requires capital letter.
    const As = as;
    return (
      <As
        {...restProps}
        className={
          // @ts-ignore
          css({
            ...yogaDefaults,
            // Reset for anchors, etc, since setting to "none" in a child
            // Text component doesn't work.
            textDecoration: "none",
            ...styles,
            ..._styles
          })
        }
      >
        {children}
      </As>
    );
  }
}

export default Box;
