import graphql from "babel-plugin-relay/macro";
import getOrderBy from "../../../util/getOrderBy";
import { Route } from "../../../types";

export const userRoute: Route = {
  getComponent: (_, cb) => {
    import("./User").then(module => cb(null, module.default));
  },
  path: "/users/:uuid",
  title: "User",
  query: graphql`
    query route_User_Query(
      $uuid: ID!
      $paginate: PageListInput!
      $orderBy: [FacilityOrderByInput!]
    ) {
      user(uuid: $uuid) {
        ...User_user
      }
    }
  `,
  prepareVariables: props => {
    const order = props.location.query.order || "asc";
    const _orderBy = props.location.query.orderBy || "name";
    const orderBy = [getOrderBy(_orderBy, order)];
    return {
      uuid: props.params.uuid,
      paginate: {
        page: props.location.query.page
          ? Math.max(parseInt(props.location.query.page, 10), 1)
          : 1,
        pageSize: 15
      },

      orderBy
    };
  }
};

export const userLocation = (uuid: string) => ({
  pathname: `/users/${uuid}`
});
