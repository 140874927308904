import graphql from "babel-plugin-relay/macro";
import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./NewFacility").then(module => cb(null, module.default));
  },
  path: "facilities/new",
  title: "New Entity",
  query: graphql`
    query route_NewFacility_Query($subGroupUuid: ID!) {
      subGroup(uuid: $subGroupUuid) {
        ...NewFacility_subGroup
      }
      subGroupList {
        ...NewFacility_subGroupList
      }
      entityLocationTypeList {
        ...NewFacility_entityLocationTypeList
      }
    }
  `,
  prepareVariables: props => ({
    subGroupUuid: props.location.query.subGroupUuid || ""
  })
};

export const newFacilityLocation: RouterToFactory = subGroupUuid => ({
  pathname: `facilities/new`,
  query: { subGroupUuid }
});
