export function setDocumentTitle(title: string | null | undefined) {
  if (title) {
    const separator = "\u2014";
    const genericTitle = document.title.includes(separator)
      ? last(document.title.split(separator)).trim()
      : document.title.trim();
    document.title = title + " " + separator + " " + genericTitle;
  }
}

function last(xs) {
  return xs[xs.length - 1];
}
