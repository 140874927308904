import graphql from "babel-plugin-relay/macro";
import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./Facility").then(module => cb(null, module.default));
  },
  path: "facilities/:facilityUuid",
  title: "Entity",
  query: graphql`
    query route_Facility_Query($uuid: ID!) {
      ...Facility_query
    }
  `,
  prepareVariables: props => ({
    uuid: props.params.facilityUuid
  }),

  getIndexRoute: (_, cb) => {
    import("../Appointments/route").then(module => cb(null, module.route));
  },
  getChildRoutes: (_, cb) => {
    Promise.all([
      // These come first so that e.g. appointments/new is not matched by
      // appointments/:uuid
      import("../CreateAppointment/route").then(module => module.route),
      import("../createWorkflow/route").then(module => module.route),
      import("../NewPatient/route").then(module => module.route),
      import("../NewStaff/route").then(module => module.route),
      import("../InitiateReferral/route").then(
        module => module.initiateReferralRoute
      ),

      import("../NewSelfPayRate/route").then(module => module.route),
      import("../DeleteSelfPayRate/route").then(module => module.route),
      import("../NewContract/route").then(module => module.newContractRoute),
      import("../NewRate/route").then(module => module.route),
      import("../NewMultiProcedureDiscount/route").then(module => module.route),
      import("../NewGlobalRule/route").then(
        module => module.newGlobalRuleRoute
      ),

      import("../NewCharityRule/route").then(
        module => module.newCharityRuleRoute
      ),

      import("../NewC2PRule/route").then(module => module.newC2PRuleRoute),
      import("../NewInventoryRule/route").then(
        module => module.newInventoryRuleRoute
      ),

      import("../NewInventoryException/route").then(
        module => module.newInventoryExceptionRoute
      ),

      import("../NewAvailableProcedure/route").then(
        module => module.newAvailableProcedureRoute
      ),

      import("../DeleteAvailableProcedure/route").then(module => module.route),
      import("../NewServiceClass/route").then(
        module => module.newServiceClassRoute
      ),

      import("../DeleteServiceClass/route").then(module => module.route),
      import("../NewPhysician/route").then(module => module.route),
      import("../DeletePhysician/route").then(module => module.route),

      import("../FacilitySettings/route").then(module => module.route),
      import("../FacilityMore/route").then(module => module.route),
      import("../FacilityMembers/route").then(module => module.route),

      import("../appointment/route").then(module => module.route),
      import("../appointment-add-procedure/route").then(module => module.route),
      import("../appointment-edit-procedures/route").then(
        module => module.route
      ),

      import("../CreateAppointmentABN/route").then(module => module.route),
      import("../AppointmentABN/route").then(module => module.route),
      import("../AppointmentDocs/route").then(module => module.route),
      import("../appointment-basic/route").then(module => module.route),
      import("../appointment-payment-details/route").then(
        module => module.route
      ),

      import("../appointment-payment-options/route").then(
        module => module.route
      ),

      import("../appointment-payment-review/route").then(
        module => module.route
      ),

      import("../appointment-payment-credit/route").then(
        module => module.route
      ),

      import("../appointment-payment-ach/route").then(module => module.route),
      import("../appointment-receipt/route").then(module => module.route),

      import("../workflow/route").then(module => module.route),
      import("../workflow-add-procedure/route").then(module => module.route),
      import("../workflow-edit-procedures/route").then(module => module.route),
      import("../CreateWorkflowABN/route").then(module => module.route),
      import("../WorkflowABN/route").then(module => module.route),
      import("../workflow-basic/route").then(module => module.route),
      import("../workflow-payment-details/route").then(module => module.route),
      import("../workflow-payment-options/route").then(module => module.route),
      import("../workflow-payment-review/route").then(module => module.route),
      import("../workflow-payment-credit/route").then(module => module.route),
      import("../workflow-payment-ach/route").then(module => module.route),
      import("../workflow-receipt/route").then(module => module.route),

      import("../patient/route").then(module => module.route),
      import("../PatientInsurance/route").then(module => module.route),
      import("../PatientPhysicians/route").then(module => module.route),
      import("../PatientPaymentAccounts/route").then(module => module.route),
      import("../PatientPaymentAccountNew/route").then(module => module.route),
      import("../patient-payment/route").then(module => module.route),
      import("../PatientPaymentOptions/route").then(module => module.route),
      import("../PatientReview/route").then(module => module.route),
      import("../patient-facility-payment-credit/route").then(
        module => module.route
      ),

      import("../patient-facility-payment-ach/route").then(
        module => module.route
      ),

      import("../patient-facility-payment-receipt/route").then(
        module => module.route
      ),

      import("../Referral/route").then(module => module.route),
      import("../Script/route").then(module => module.route),
      import("../MessageTemplate/route").then(module => module.route),
      import("../navigationEditor/route").then(module => module.route),

      // import("../Members/route").then(module => module.route),
      import("../Patients/route").then(module => module.route),
      import("../Payments/route").then(module => module.route),
      import("../Workflows/route").then(module => module.route),
      import("../Referrals/route").then(module => module.route),
      import("../StaffTotals/route").then(module => module.route),
      import("../Rates/route").then(module => module.ratesRoute),
      import("../SelfPayRates/route").then(module => module.selfPayRatesRoute),
      import("../Contracts/route").then(module => module.contractsRoute),
      import("../GlobalRules/route").then(module => module.globalRulesRoute),
      import("../CharityRules/route").then(module => module.charityRulesRoute),
      import("../C2PRules/route").then(module => module.c2pRulesRoute),
      import("../InventoryRules/route").then(
        module => module.inventoryRulesRoute
      ),

      import("../InventoryExceptions/route").then(
        module => module.inventoryExceptionsRoute
      ),

      import("../AvailableProcedures/route").then(
        module => module.availableProceduresRoute
      ),

      import("../MultiProcedureDiscounts/route").then(module => module.route),
      import("../ServiceClasses/route").then(
        module => module.serviceClassesRoute
      ),

      import("../Physicians/route").then(module => module.physiciansRoute),
      import("../Reports/route").then(module => module.reportsRoute),
      import("../Scripts/route").then(module => module.scriptsRoute),
      import("../messagingEvents/route").then(
        module => module.messagingEventsRoute
      ),

      import("../LineOfCreditApply/route").then(module => module.route),
      import("../EDI/route").then(module => module.route),

      import("../boards/route").then(module => module.route),
      import("../board/route").then(module => module.route)

      // import { pacReferralNeedsRoute } from "./components/screens/pacReferralNeeds/route";
      // import { pacReferralsRoute } from "./components/screens/pacReferrals/route";
      // import { pacReferralRoute } from "./components/screens/pacReferral/route";
      // import { bedAvailabilityRoute } from "./components/screens/bedAvailability/route";
      // import { createBedRoute } from "./components/screens/createBed/route";
      // import { createDischargePatientRoute } from "./components/screens/createDischargePatient/route";
      // import { pacFacilitiesResultsRoute } from "./components/screens/pacFacilitiesResults/route";
      // import { pacFacilityResultsRoute } from "./components/screens/pacFacilityResults/route";
      // import { pacFacilitySelectionRoute } from "./components/screens/pacFacilitySelection/route";
    ]).then(routes => cb(null, routes));
  }
};

// TODO export function to merge?
// e.g. take appointments location and turn into
// /facilities/1/appointments

export const facilityLocation: RouterToFactory = uuid => ({
  pathname: `/facilities/${uuid}`
});
