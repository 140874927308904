/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { NewSubGroup_group$ref } from "./NewSubGroup_group.graphql";
import { NewSubGroup_groupList$ref } from "./NewSubGroup_groupList.graphql";
export type route_NewSubGroup_QueryVariables = {
    readonly groupUuid: string;
};
export type route_NewSubGroup_QueryResponse = {
    readonly group: {
        readonly " $fragmentRefs": NewSubGroup_group$ref;
    } | null;
    readonly groupList: ReadonlyArray<{
        readonly " $fragmentRefs": NewSubGroup_groupList$ref;
    } | null> | null;
};
export type route_NewSubGroup_Query = {
    readonly response: route_NewSubGroup_QueryResponse;
    readonly variables: route_NewSubGroup_QueryVariables;
};



/*
query route_NewSubGroup_Query(
  $groupUuid: ID!
) {
  group(uuid: $groupUuid) {
    ...NewSubGroup_group
    id
  }
  groupList {
    ...NewSubGroup_groupList
    id
  }
}

fragment NewSubGroup_group on Group {
  uuid
}

fragment NewSubGroup_groupList on Group {
  uuid
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "groupUuid",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "groupUuid"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_NewSubGroup_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewSubGroup_group",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "groupList",
        "storageKey": null,
        "args": null,
        "concreteType": "Group",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewSubGroup_groupList",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_NewSubGroup_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "group",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "groupList",
        "storageKey": null,
        "args": null,
        "concreteType": "Group",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_NewSubGroup_Query",
    "id": null,
    "text": "query route_NewSubGroup_Query(\n  $groupUuid: ID!\n) {\n  group(uuid: $groupUuid) {\n    ...NewSubGroup_group\n    id\n  }\n  groupList {\n    ...NewSubGroup_groupList\n    id\n  }\n}\n\nfragment NewSubGroup_group on Group {\n  uuid\n}\n\nfragment NewSubGroup_groupList on Group {\n  uuid\n  name\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'eb22e5c684fd1005c085cc733e155ef1';
export default node;
