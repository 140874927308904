import graphql from "babel-plugin-relay/macro";

import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./Facilities").then(module => cb(null, module.default));
  },
  path: "facilities",
  title: "Entities",
  query: graphql`
    query route_Facilities_Query(
      $subGroupUuid: ID!
      $facilityWhere: FacilityWhereInput!
    ) {
      ...Facilities_query
      subGroup(uuid: $subGroupUuid) {
        ...Facilities_subGroup
      }
    }
  `,
  prepareVariables: props => ({
    subGroupUuid: props.params.subGroupUuid,
    facilityWhere: {
      ownerEntityUuid: props.params.subGroupUuid
    }
  })
};

export const facilitiesLocation: RouterToFactory = subGroupUuid => ({
  pathname: `/facilities`,
  query: { subGroupUuid }
});
