/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { Channel_channel$ref } from "./Channel_channel.graphql";
export type route_channel_QueryVariables = {};
export type route_channel_QueryResponse = {
    readonly channel: {
        readonly " $fragmentRefs": Channel_channel$ref;
    } | null;
};
export type route_channel_Query = {
    readonly response: route_channel_QueryResponse;
    readonly variables: route_channel_QueryVariables;
};



/*
query route_channel_Query {
  channel {
    ...Channel_channel
    id
  }
}

fragment Channel_channel on Channel {
  uuid
  name
  site
  phone
}
*/

const node: ConcreteRequest = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_channel_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "channel",
        "storageKey": null,
        "args": null,
        "concreteType": "Channel",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Channel_channel",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_channel_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "channel",
        "storageKey": null,
        "args": null,
        "concreteType": "Channel",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "uuid",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "site",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_channel_Query",
    "id": null,
    "text": "query route_channel_Query {\n  channel {\n    ...Channel_channel\n    id\n  }\n}\n\nfragment Channel_channel on Channel {\n  uuid\n  name\n  site\n  phone\n}\n",
    "metadata": {}
  }
};
(node as any).hash = '05cc254b0de64359418360a7c0ee9421';
export default node;
