import { Route, RouterToFactory } from "../../../types";

export const loginRoute: Route = {
  getComponent: (_, cb) => {
    import("./Login").then(module => cb(null, module.default));
  },
  path: "login",
  title: "Login"
};

export const loginLocation: RouterToFactory = returnTo => ({
  pathname: "/login",
  state: { returnTo }
});
