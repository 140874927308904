import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const addFacilityRoute: Route = {
  getComponent: (_, cb) => {
    import("./AddFacility").then(module => cb(null, module.default));
  },
  path: "/staff/:uuid/add-entity",
  title: "Add Entity",
  query: graphql`
    query route_add_facility_Query($uuid: ID!, $count: Int) {
      viewer {
        ...AddFacility_viewer
      }
      user(uuid: $uuid) {
        ...AddFacility_user
      }
    }
  `,
  prepareVariables: props => ({
    uuid: props.params.uuid,
    count: 1000
  })
};

export const addFacilityLocation = (uuid: string) => ({
  pathname: `/staff/${uuid}/add-entity`,
  state: { isModal: true }
});
