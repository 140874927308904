import Color from "color";
import typography from "./typography";

// https://material.io/guidelines/style/color.html#color-color-palette
const gray = {
  gray50: "#FAFAFA",
  gray100: "#F5F5F5",
  gray200: "#EEEEEE",
  gray300: "#E0E0E0",
  gray400: "#BDBDBD",
  gray500: "#9E9E9E",
  gray600: "#757575",
  gray700: "#616161",
  gray800: "#424242",
  gray900: "#212121"
};

const brand = {
  main: gray.gray900
};

export default {
  typography: typography({
    fontSize: 14,
    fontSizeScale: "step2",
    lineHeight: 21
  }),
  text: {
    bold: "600",
    color: gray.gray800,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
  },
  spacing: { xs: "4px", s: "8px", m: "16px", l: "32px" },
  borderRadius: {
    s: "4px",
    // This is intentional.
    m: "4px",
    l: "6px"
  },
  color: {
    primary: {
      lighter: Color("#1b69b6")
        .lighten(0.1)
        .toString(),
      normal: "#1b69b6",
      darker: Color("#1b69b6")
        .darken(0.1)
        .toString()
    },
    success: {
      // lighter: Color("rgb(91, 222, 121)").lighten(0.4).string(),
      lighter: "rgb(195, 243, 206)",
      normal: "rgb(26, 170, 85)",
      darker: "rgb(22, 143, 72)"
    },
    info: {
      normal: "rgb(255, 213, 0)"
    },
    danger: {
      lightest: Color("#dc3545")
        .lighten(0.8)
        .string(),
      lighter: Color("#dc3545")
        .lighten(0.4)
        .string(),
      normal: "#dc3545",
      darker: Color("#dc3545")
        .darken(0.1)
        .string()
    },
    ...gray,
    brand
  },
  zIndex: {
    // Setting tooltip zIndexes lower than the overlay/modalOverly
    // zIndexes means that we can't have a tooltip within a modal,
    // but prevents visual glitches when a modal is open in a background
    // screen with tooltips present.
    tooltip: 98,
    tooltipWrapper: 99,
    overlay: 100,
    modalOverlay: 101,
    progress: 102,
    activityIndicator: 103,
    alert: 104
  },
  header: {
    backgroundColor: brand.main,
    color: "white"
  }
};
