/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { User_user$ref } from "./User_user.graphql";
export type FacilityOrderByInput = "addressUuid_ASC" | "addressUuid_DESC" | "avatarImageLocation_ASC" | "avatarImageLocation_DESC" | "childEntityType_ASC" | "childEntityType_DESC" | "clientId_ASC" | "clientId_DESC" | "created_ASC" | "created_DESC" | "creator_ASC" | "creator_DESC" | "description_ASC" | "description_DESC" | "entityLocationTypeUuid_ASC" | "entityLocationTypeUuid_DESC" | "entityType_ASC" | "entityType_DESC" | "logoImageLocation_ASC" | "logoImageLocation_DESC" | "name_ASC" | "name_DESC" | "npi_ASC" | "npi_DESC" | "ownerEntityType_ASC" | "ownerEntityType_DESC" | "ownerEntityUuid_ASC" | "ownerEntityUuid_DESC" | "phone_ASC" | "phone_DESC" | "photoImageLocation_ASC" | "photoImageLocation_DESC" | "rating_ASC" | "rating_DESC" | "site_ASC" | "site_DESC" | "uuid_ASC" | "uuid_DESC" | "%future added value";
export type PageListInput = {
    readonly page: number;
    readonly pageSize?: number | null;
};
export type route_User_QueryVariables = {
    readonly uuid: string;
    readonly paginate: PageListInput;
    readonly orderBy?: ReadonlyArray<FacilityOrderByInput> | null;
};
export type route_User_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": User_user$ref;
    } | null;
};
export type route_User_Query = {
    readonly response: route_User_QueryResponse;
    readonly variables: route_User_QueryVariables;
};



/*
query route_User_Query(
  $uuid: ID!
  $paginate: PageListInput!
  $orderBy: [FacilityOrderByInput!]
) {
  user(uuid: $uuid) {
    ...User_user
    id
  }
}

fragment User_user on User {
  uuid
  name
  created
  facilityPageList(paginate: $paginate, orderBy: $orderBy) {
    info {
      page
      hasPreviousPage
      hasNextPage
      lastPage
      totalCount
    }
    data {
      uuid
      name
      npi
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uuid",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paginate",
    "type": "PageListInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[FacilityOrderByInput!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_User_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "User_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_User_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "facilityPageList",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              },
              {
                "kind": "Variable",
                "name": "paginate",
                "variableName": "paginate"
              }
            ],
            "concreteType": "FacilityPageList",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "info",
                "storageKey": null,
                "args": null,
                "concreteType": "PageListInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "page",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "data",
                "storageKey": null,
                "args": null,
                "concreteType": "Facility",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "npi",
                    "args": null,
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_User_Query",
    "id": null,
    "text": "query route_User_Query(\n  $uuid: ID!\n  $paginate: PageListInput!\n  $orderBy: [FacilityOrderByInput!]\n) {\n  user(uuid: $uuid) {\n    ...User_user\n    id\n  }\n}\n\nfragment User_user on User {\n  uuid\n  name\n  created\n  facilityPageList(paginate: $paginate, orderBy: $orderBy) {\n    info {\n      page\n      hasPreviousPage\n      hasNextPage\n      lastPage\n      totalCount\n    }\n    data {\n      uuid\n      name\n      npi\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ba056dac091125d5d3d1b34e36be1e7e';
export default node;
