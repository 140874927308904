type AppData = {
  logoURL: string;
  displayName: string;
  primaryColor: string;
};

const appData: AppData =
  // @ts-ignore
  window.__APP_DATA__;

export default appData;
