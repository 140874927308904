/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { Facilities_query$ref } from "./Facilities_query.graphql";
import { Facilities_subGroup$ref } from "./Facilities_subGroup.graphql";
export type FacilityWhereInput = {
    readonly addressUuid?: string | null;
    readonly addressUuid_btwn?: ReadonlyArray<string> | null;
    readonly addressUuid_gt?: string | null;
    readonly addressUuid_gte?: string | null;
    readonly addressUuid_in?: ReadonlyArray<string> | null;
    readonly addressUuid_lt?: string | null;
    readonly addressUuid_lte?: string | null;
    readonly addressUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly addressUuid_nin?: ReadonlyArray<string> | null;
    readonly AND?: ReadonlyArray<FacilityWhereInput> | null;
    readonly avatarImageLocation?: string | null;
    readonly avatarImageLocation_btwn?: ReadonlyArray<string> | null;
    readonly avatarImageLocation_gt?: string | null;
    readonly avatarImageLocation_gte?: string | null;
    readonly avatarImageLocation_in?: ReadonlyArray<string> | null;
    readonly avatarImageLocation_lt?: string | null;
    readonly avatarImageLocation_lte?: string | null;
    readonly avatarImageLocation_nbtwn?: ReadonlyArray<string> | null;
    readonly avatarImageLocation_nin?: ReadonlyArray<string> | null;
    readonly childEntityType?: string | null;
    readonly childEntityType_btwn?: ReadonlyArray<string> | null;
    readonly childEntityType_gt?: string | null;
    readonly childEntityType_gte?: string | null;
    readonly childEntityType_in?: ReadonlyArray<string> | null;
    readonly childEntityType_lt?: string | null;
    readonly childEntityType_lte?: string | null;
    readonly childEntityType_nbtwn?: ReadonlyArray<string> | null;
    readonly childEntityType_nin?: ReadonlyArray<string> | null;
    readonly clientId?: string | null;
    readonly clientId_btwn?: ReadonlyArray<string> | null;
    readonly clientId_gt?: string | null;
    readonly clientId_gte?: string | null;
    readonly clientId_in?: ReadonlyArray<string> | null;
    readonly clientId_lt?: string | null;
    readonly clientId_lte?: string | null;
    readonly clientId_nbtwn?: ReadonlyArray<string> | null;
    readonly clientId_nin?: ReadonlyArray<string> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly description?: string | null;
    readonly description_btwn?: ReadonlyArray<string> | null;
    readonly description_gt?: string | null;
    readonly description_gte?: string | null;
    readonly description_in?: ReadonlyArray<string> | null;
    readonly description_lt?: string | null;
    readonly description_lte?: string | null;
    readonly description_nbtwn?: ReadonlyArray<string> | null;
    readonly description_nin?: ReadonlyArray<string> | null;
    readonly entityLocationTypeUuid?: string | null;
    readonly entityLocationTypeUuid_btwn?: ReadonlyArray<string> | null;
    readonly entityLocationTypeUuid_gt?: string | null;
    readonly entityLocationTypeUuid_gte?: string | null;
    readonly entityLocationTypeUuid_in?: ReadonlyArray<string> | null;
    readonly entityLocationTypeUuid_lt?: string | null;
    readonly entityLocationTypeUuid_lte?: string | null;
    readonly entityLocationTypeUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly entityLocationTypeUuid_nin?: ReadonlyArray<string> | null;
    readonly entityType?: string | null;
    readonly entityType_btwn?: ReadonlyArray<string> | null;
    readonly entityType_gt?: string | null;
    readonly entityType_gte?: string | null;
    readonly entityType_in?: ReadonlyArray<string> | null;
    readonly entityType_lt?: string | null;
    readonly entityType_lte?: string | null;
    readonly entityType_nbtwn?: ReadonlyArray<string> | null;
    readonly entityType_nin?: ReadonlyArray<string> | null;
    readonly logoImageLocation?: string | null;
    readonly logoImageLocation_btwn?: ReadonlyArray<string> | null;
    readonly logoImageLocation_gt?: string | null;
    readonly logoImageLocation_gte?: string | null;
    readonly logoImageLocation_in?: ReadonlyArray<string> | null;
    readonly logoImageLocation_lt?: string | null;
    readonly logoImageLocation_lte?: string | null;
    readonly logoImageLocation_nbtwn?: ReadonlyArray<string> | null;
    readonly logoImageLocation_nin?: ReadonlyArray<string> | null;
    readonly name?: string | null;
    readonly name_btwn?: ReadonlyArray<string> | null;
    readonly name_gt?: string | null;
    readonly name_gte?: string | null;
    readonly name_in?: ReadonlyArray<string> | null;
    readonly name_lt?: string | null;
    readonly name_lte?: string | null;
    readonly name_nbtwn?: ReadonlyArray<string> | null;
    readonly name_nin?: ReadonlyArray<string> | null;
    readonly npi?: string | null;
    readonly npi_btwn?: ReadonlyArray<string> | null;
    readonly npi_gt?: string | null;
    readonly npi_gte?: string | null;
    readonly npi_in?: ReadonlyArray<string> | null;
    readonly npi_lt?: string | null;
    readonly npi_lte?: string | null;
    readonly npi_nbtwn?: ReadonlyArray<string> | null;
    readonly npi_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<FacilityWhereInput> | null;
    readonly ownerEntityType?: string | null;
    readonly ownerEntityType_btwn?: ReadonlyArray<string> | null;
    readonly ownerEntityType_gt?: string | null;
    readonly ownerEntityType_gte?: string | null;
    readonly ownerEntityType_in?: ReadonlyArray<string> | null;
    readonly ownerEntityType_lt?: string | null;
    readonly ownerEntityType_lte?: string | null;
    readonly ownerEntityType_nbtwn?: ReadonlyArray<string> | null;
    readonly ownerEntityType_nin?: ReadonlyArray<string> | null;
    readonly ownerEntityUuid?: string | null;
    readonly ownerEntityUuid_btwn?: ReadonlyArray<string> | null;
    readonly ownerEntityUuid_gt?: string | null;
    readonly ownerEntityUuid_gte?: string | null;
    readonly ownerEntityUuid_in?: ReadonlyArray<string> | null;
    readonly ownerEntityUuid_lt?: string | null;
    readonly ownerEntityUuid_lte?: string | null;
    readonly ownerEntityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly ownerEntityUuid_nin?: ReadonlyArray<string> | null;
    readonly phone?: string | null;
    readonly phone_btwn?: ReadonlyArray<string> | null;
    readonly phone_gt?: string | null;
    readonly phone_gte?: string | null;
    readonly phone_in?: ReadonlyArray<string> | null;
    readonly phone_lt?: string | null;
    readonly phone_lte?: string | null;
    readonly phone_nbtwn?: ReadonlyArray<string> | null;
    readonly phone_nin?: ReadonlyArray<string> | null;
    readonly photoImageLocation?: string | null;
    readonly photoImageLocation_btwn?: ReadonlyArray<string> | null;
    readonly photoImageLocation_gt?: string | null;
    readonly photoImageLocation_gte?: string | null;
    readonly photoImageLocation_in?: ReadonlyArray<string> | null;
    readonly photoImageLocation_lt?: string | null;
    readonly photoImageLocation_lte?: string | null;
    readonly photoImageLocation_nbtwn?: ReadonlyArray<string> | null;
    readonly photoImageLocation_nin?: ReadonlyArray<string> | null;
    readonly rating?: number | null;
    readonly rating_btwn?: ReadonlyArray<number> | null;
    readonly rating_gt?: number | null;
    readonly rating_gte?: number | null;
    readonly rating_in?: ReadonlyArray<number> | null;
    readonly rating_lt?: number | null;
    readonly rating_lte?: number | null;
    readonly rating_nbtwn?: ReadonlyArray<number> | null;
    readonly rating_nin?: ReadonlyArray<number> | null;
    readonly site?: string | null;
    readonly site_btwn?: ReadonlyArray<string> | null;
    readonly site_gt?: string | null;
    readonly site_gte?: string | null;
    readonly site_in?: ReadonlyArray<string> | null;
    readonly site_lt?: string | null;
    readonly site_lte?: string | null;
    readonly site_nbtwn?: ReadonlyArray<string> | null;
    readonly site_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type route_Facilities_QueryVariables = {
    readonly subGroupUuid: string;
    readonly facilityWhere: FacilityWhereInput;
};
export type route_Facilities_QueryResponse = {
    readonly subGroup: {
        readonly " $fragmentRefs": Facilities_subGroup$ref;
    } | null;
    readonly " $fragmentRefs": Facilities_query$ref;
};
export type route_Facilities_Query = {
    readonly response: route_Facilities_QueryResponse;
    readonly variables: route_Facilities_QueryVariables;
};



/*
query route_Facilities_Query(
  $subGroupUuid: ID!
  $facilityWhere: FacilityWhereInput!
) {
  ...Facilities_query
  subGroup(uuid: $subGroupUuid) {
    ...Facilities_subGroup
    id
  }
}

fragment Facilities_query on Query {
  facilityList(where: $facilityWhere) {
    uuid
    name
    logoImageLocation
    id
  }
}

fragment Facilities_subGroup on SubGroup {
  uuid
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subGroupUuid",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "facilityWhere",
    "type": "FacilityWhereInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "subGroupUuid"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_Facilities_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SubGroup",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "Facilities_subGroup",
            "args": null
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "Facilities_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_Facilities_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "facilityList",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "facilityWhere"
          }
        ],
        "concreteType": "Facility",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "logoImageLocation",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SubGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_Facilities_Query",
    "id": null,
    "text": "query route_Facilities_Query(\n  $subGroupUuid: ID!\n  $facilityWhere: FacilityWhereInput!\n) {\n  ...Facilities_query\n  subGroup(uuid: $subGroupUuid) {\n    ...Facilities_subGroup\n    id\n  }\n}\n\nfragment Facilities_query on Query {\n  facilityList(where: $facilityWhere) {\n    uuid\n    name\n    logoImageLocation\n    id\n  }\n}\n\nfragment Facilities_subGroup on SubGroup {\n  uuid\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '936b3af29b1df60831757f0291b34bfa';
export default node;
