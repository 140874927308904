export function getNavCollapse() {
  const x = window.localStorage.getItem("pos_nav_collapse");
  return !!(x && JSON.parse(x));
}

export function setNavCollapse(value: boolean) {
  window.localStorage.setItem("pos_nav_collapse", String(value));
}

export function getNavWidth() {
  // lol.
  if (
    window.location.pathname.startsWith("/facilities/") ||
    window.location.pathname.startsWith("/groups/") ||
    window.location.pathname.startsWith("/sub-groups/")
  ) {
    const x = window.localStorage.getItem("pos_nav_collapse");
    const collapse = x && JSON.parse(x);

    if (collapse) {
      return 55;
    }
    return 205;
  }

  return 0;
}
