import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache,
  Observable
} from "relay-runtime";
import uuid from "uuid";
import { getToken } from "./util/auth";
import { REACT_APP_API_BASE_URL } from "./config";

export function createRelayEnvironment(handle401: () => void) {
  const responseCache = new QueryResponseCache({
    size: 500,
    ttl: 1000 * 60 * 5
  });

  return new Environment({
    network: Network.create(createFetchQuery(responseCache, handle401)),
    store: new Store(new RecordSource())
  });
}

function createFetchQuery(responseCache, handle401) {
  return (operation, variables, cacheConfig, uploadables) => {
    const cacheKey = operation.text;
    if (operation.operationKind === "query") {
      const cachedResponse = responseCache.get(cacheKey, variables);
      if (cachedResponse && !cacheConfig.force) {
        return cachedResponse;
      }
    } else if (operation.operationKind === "mutation") {
      // TODO: This is heavy-handed, but simpler and less error-prone
      // than specifying mutation configs/updater functions.
      responseCache.clear();
    }

    const token = getToken();

    let init;
    // https://github.com/facebook/relay/blob/master/packages/react-relay/classic/network-layer/default/RelayDefaultNetworkLayer.js#L97
    if (uploadables) {
      if (typeof FormData === "undefined") {
        throw new Error("Uploading files without `FormData` not supported.");
      }
      const formData = new FormData();
      formData.append("query", operation.text);
      formData.append("variables", JSON.stringify(variables));
      for (const filename in uploadables) {
        if (Object.prototype.hasOwnProperty.call(uploadables, filename)) {
          formData.append(filename, uploadables[filename]);
        }
      }
      init = {
        method: "POST",
        headers: {
          "x-request-id": uuid.v4(),
          Authorization: token ? `Bearer ${token}` : ""
        },
        body: formData
      };
    } else {
      init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-request-id": uuid.v4(),
          Authorization: token ? `Bearer ${token}` : ""
        },
        body: JSON.stringify({
          query: operation.text,
          variables
        })
      };
    }
    const url = `${REACT_APP_API_BASE_URL}/graphql`;

    // https://ollie.relph.me/relay-modern-easy-polling/
    return Observable.create(sink => {
      fetch(url, init)
        .then(response => {
          if (response.status === 401) {
            // FIXME: This causes retries. It also throws an error,
            // resulting in GenericError being rendered by the QueryRenderer.
            handle401();
            return;
          }
          return response.json();
        })
        .then(json => {
          if (json && !json.errors) {
            responseCache.set(cacheKey, variables, json);
          }
          sink.next(json);
          sink.complete();
        });
    });
  };
}
