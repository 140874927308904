export const dimensions = {
  header: {
    height: 46
  },
  main: {
    padding: {
      top: 20,
      bottom: 20
    }
  }
};
