import * as React from "react";
import { css } from "emotion";
import theme from "../themes/theme";

type Props = {
  children: string;
  styles?: { [k: string]: string | number };
};

const H1 = (props: Props) => (
  <h1
    className={css({
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1,
      margin: 0,
      padding: 0,
      color: theme.color.gray900,
      ...props.styles
    })}
  >
    {props.children}
  </h1>
);

export default H1;
