/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { SettingsProfile_viewer$ref } from "./SettingsProfile_viewer.graphql";
export type route_SettingsProfile_QueryVariables = {};
export type route_SettingsProfile_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": SettingsProfile_viewer$ref;
    } | null;
};
export type route_SettingsProfile_Query = {
    readonly response: route_SettingsProfile_QueryResponse;
    readonly variables: route_SettingsProfile_QueryVariables;
};



/*
query route_SettingsProfile_Query {
  viewer {
    ...SettingsProfile_viewer
    id
  }
}

fragment SettingsProfile_viewer on User {
  uuid
  firstName
  middleName
  lastName
  email
}
*/

const node: ConcreteRequest = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_SettingsProfile_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SettingsProfile_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_SettingsProfile_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "uuid",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "middleName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_SettingsProfile_Query",
    "id": null,
    "text": "query route_SettingsProfile_Query {\n  viewer {\n    ...SettingsProfile_viewer\n    id\n  }\n}\n\nfragment SettingsProfile_viewer on User {\n  uuid\n  firstName\n  middleName\n  lastName\n  email\n}\n",
    "metadata": {}
  }
};
(node as any).hash = 'cb1137c84fa2280490c1a71577333dd3';
export default node;
