/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { Facility_query$ref } from "./Facility_query.graphql";
export type route_Facility_QueryVariables = {
    readonly uuid: string;
};
export type route_Facility_QueryResponse = {
    readonly " $fragmentRefs": Facility_query$ref;
};
export type route_Facility_Query = {
    readonly response: route_Facility_QueryResponse;
    readonly variables: route_Facility_QueryVariables;
};



/*
query route_Facility_Query(
  $uuid: ID!
) {
  ...Facility_query
}

fragment Facility_query on Query {
  facility(uuid: $uuid) {
    name
    logoImageLocation
    ...Breadcrumb_facility
    id
  }
}

fragment Breadcrumb_facility on Facility {
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uuid",
    "type": "ID!",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_Facility_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Facility_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_Facility_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "facility",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "concreteType": "Facility",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "logoImageLocation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_Facility_Query",
    "id": null,
    "text": "query route_Facility_Query(\n  $uuid: ID!\n) {\n  ...Facility_query\n}\n\nfragment Facility_query on Query {\n  facility(uuid: $uuid) {\n    name\n    logoImageLocation\n    ...Breadcrumb_facility\n    id\n  }\n}\n\nfragment Breadcrumb_facility on Facility {\n  name\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ee8ba41be2a500ad11ba6fde23bcb5cd';
export default node;
