import * as React from "react";
import { css } from "emotion";
import UserDropdown from "./UserDropdown";
import GroupDropdown from "./GroupDropdown";
import SubGroupDropdown from "./SubGroupDropdown";
import FacilityDropdown from "./FacilityDropdown";
import MarketplaceDropdown from "./MarketplaceDropdown";

export default function HeaderChildren() {
  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 1,
        paddingLeft: 20
      })}
    >
      <div className={css({ display: "flex", flexShrink: 1 })}>
        <GroupDropdown />
        <SubGroupDropdown />
        <FacilityDropdown />
        <MarketplaceDropdown />
      </div>
      <div className={css({ width: 20 })} />
      <UserDropdown />
    </div>
  );
}
