/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { AddFacility_user$ref } from "./AddFacility_user.graphql";
import { AddFacility_viewer$ref } from "./AddFacility_viewer.graphql";
export type route_add_facility_QueryVariables = {
    readonly uuid: string;
    readonly count?: number | null;
};
export type route_add_facility_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": AddFacility_viewer$ref;
    } | null;
    readonly user: {
        readonly " $fragmentRefs": AddFacility_user$ref;
    } | null;
};
export type route_add_facility_Query = {
    readonly response: route_add_facility_QueryResponse;
    readonly variables: route_add_facility_QueryVariables;
};



/*
query route_add_facility_Query(
  $uuid: ID!
  $count: Int
) {
  viewer {
    ...AddFacility_viewer
    id
  }
  user(uuid: $uuid) {
    ...AddFacility_user
    id
  }
}

fragment AddFacility_viewer on User {
  facilityConnection(first: $count) {
    edges {
      node {
        uuid
        name
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AddFacility_user on User {
  uuid
  facilityConnection(first: $count) {
    edges {
      node {
        uuid
        name
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uuid",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "facilityConnection",
  "storageKey": null,
  "args": (v2/*: any*/),
  "concreteType": "FacilityConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FacilityEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Facility",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "__typename",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cursor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "PageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "endCursor",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasNextPage",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_add_facility_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AddFacility_viewer",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AddFacility_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_add_facility_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "facilityConnection",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "AddFacility_viewer_facilityConnection",
            "filters": null
          },
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "facilityConnection",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "AddFacility_user_facilityConnection",
            "filters": null
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_add_facility_Query",
    "id": null,
    "text": "query route_add_facility_Query(\n  $uuid: ID!\n  $count: Int\n) {\n  viewer {\n    ...AddFacility_viewer\n    id\n  }\n  user(uuid: $uuid) {\n    ...AddFacility_user\n    id\n  }\n}\n\nfragment AddFacility_viewer on User {\n  facilityConnection(first: $count) {\n    edges {\n      node {\n        uuid\n        name\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AddFacility_user on User {\n  uuid\n  facilityConnection(first: $count) {\n    edges {\n      node {\n        uuid\n        name\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '32be429ad51533fa00093fe57766c13c';
export default node;
