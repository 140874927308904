/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { UserPermissions_query$ref } from "./UserPermissions_query.graphql";
export type route_UserPermissions_QueryVariables = {
    readonly uuid: string;
};
export type route_UserPermissions_QueryResponse = {
    readonly " $fragmentRefs": UserPermissions_query$ref;
};
export type route_UserPermissions_Query = {
    readonly response: route_UserPermissions_QueryResponse;
    readonly variables: route_UserPermissions_QueryVariables;
};



/*
query route_UserPermissions_Query(
  $uuid: ID!
) {
  ...UserPermissions_query
}

fragment UserPermissions_query on Query {
  user(uuid: $uuid) {
    uuid
    name
    created
    ruleList {
      uuid
      actionUuid
      effect
      id
    }
    id
  }
  actionList {
    uuid
    displayName
    accessGroup
    category
    id
  }
  canEditPermissions(userUuid: $uuid)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uuid",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_UserPermissions_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "UserPermissions_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_UserPermissions_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "uuid",
            "variableName": "uuid"
          }
        ],
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ruleList",
            "storageKey": null,
            "args": null,
            "concreteType": "Rule",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actionUuid",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "effect",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/)
            ]
          },
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "actionList",
        "storageKey": null,
        "args": null,
        "concreteType": "Action",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "displayName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accessGroup",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "category",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "canEditPermissions",
        "args": [
          {
            "kind": "Variable",
            "name": "userUuid",
            "variableName": "uuid"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_UserPermissions_Query",
    "id": null,
    "text": "query route_UserPermissions_Query(\n  $uuid: ID!\n) {\n  ...UserPermissions_query\n}\n\nfragment UserPermissions_query on Query {\n  user(uuid: $uuid) {\n    uuid\n    name\n    created\n    ruleList {\n      uuid\n      actionUuid\n      effect\n      id\n    }\n    id\n  }\n  actionList {\n    uuid\n    displayName\n    accessGroup\n    category\n    id\n  }\n  canEditPermissions(userUuid: $uuid)\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '77fa12333b76b91135524fc3e8bb24e9';
export default node;
