import * as React from "react";
import { css } from "emotion";
import Box from "./Box";
import "./ActivityIndicator.css";

type ActivityIndicatorProps = {
  kind: "dark" | "light";
  size: "s" | "m";
  fixed?: true;
};

type ActivityIndicatorState = {
  showIndicator: boolean;
};

class ActivityIndicator extends React.Component<
  ActivityIndicatorProps,
  ActivityIndicatorState
> {
  timerId?: ReturnType<typeof setTimeout>;

  constructor(props: ActivityIndicatorProps) {
    super(props);
    this.timerId = undefined;
    this.state = { showIndicator: false };
  }

  componentDidMount() {
    // https://www.nngroup.com/articles/response-times-3-important-limits/
    this.timerId = setTimeout(() => {
      this.setState({ showIndicator: true });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  render() {
    if (!this.state.showIndicator) {
      return null;
    }
    const indicator = (
      <Box
        styles={{
          width: this.props.size === "m" ? "48px" : "24px",
          height: this.props.size === "m" ? "48px" : "24px"
        }}
      >
        <svg
          viewBox="0 0 32 32"
          className={css({
            animationDuration: "0.75s",
            animationName: "ActivityIndicator-animation",
            animationTimingFunction: "linear",
            animationIterationCount: "infinite"
          })}
        >
          <circle
            stroke={this.props.kind === "dark" ? "black" : "white"}
            opacity={0.2}
            cx="16px"
            cy="16px"
            fill="none"
            r="14px"
            strokeWidth="4px"
          />

          <circle
            stroke={this.props.kind === "dark" ? "black" : "white"}
            strokeDasharray={80}
            strokeDashoffset={60}
            cx="16px"
            cy="16px"
            fill="none"
            r="14px"
            strokeWidth="4px"
          />
        </svg>
      </Box>
    );

    if (this.props.fixed) {
      return (
        <Box
          styles={{
            top: "50%",
            left: "50%"
          }}
          _styles={{
            position: "fixed",
            transform: "translate(-50%, -50%)"
          }}
        >
          {indicator}
        </Box>
      );
    } else {
      return indicator;
    }
  }
}

export default ActivityIndicator;
