/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { Home_query$ref } from "./Home_query.graphql";
export type UserChannelWhereInput = {
    readonly AND?: ReadonlyArray<UserChannelWhereInput> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly entityUuid?: string | null;
    readonly entityUuid_btwn?: ReadonlyArray<string> | null;
    readonly entityUuid_gt?: string | null;
    readonly entityUuid_gte?: string | null;
    readonly entityUuid_in?: ReadonlyArray<string> | null;
    readonly entityUuid_lt?: string | null;
    readonly entityUuid_lte?: string | null;
    readonly entityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly entityUuid_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<UserChannelWhereInput> | null;
    readonly userUuid?: string | null;
    readonly userUuid_btwn?: ReadonlyArray<string> | null;
    readonly userUuid_gt?: string | null;
    readonly userUuid_gte?: string | null;
    readonly userUuid_in?: ReadonlyArray<string> | null;
    readonly userUuid_lt?: string | null;
    readonly userUuid_lte?: string | null;
    readonly userUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly userUuid_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type UserGroupWhereInput = {
    readonly AND?: ReadonlyArray<UserGroupWhereInput> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly entityUuid?: string | null;
    readonly entityUuid_btwn?: ReadonlyArray<string> | null;
    readonly entityUuid_gt?: string | null;
    readonly entityUuid_gte?: string | null;
    readonly entityUuid_in?: ReadonlyArray<string> | null;
    readonly entityUuid_lt?: string | null;
    readonly entityUuid_lte?: string | null;
    readonly entityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly entityUuid_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<UserGroupWhereInput> | null;
    readonly userUuid?: string | null;
    readonly userUuid_btwn?: ReadonlyArray<string> | null;
    readonly userUuid_gt?: string | null;
    readonly userUuid_gte?: string | null;
    readonly userUuid_in?: ReadonlyArray<string> | null;
    readonly userUuid_lt?: string | null;
    readonly userUuid_lte?: string | null;
    readonly userUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly userUuid_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type UserSubGroupWhereInput = {
    readonly AND?: ReadonlyArray<UserSubGroupWhereInput> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly entityUuid?: string | null;
    readonly entityUuid_btwn?: ReadonlyArray<string> | null;
    readonly entityUuid_gt?: string | null;
    readonly entityUuid_gte?: string | null;
    readonly entityUuid_in?: ReadonlyArray<string> | null;
    readonly entityUuid_lt?: string | null;
    readonly entityUuid_lte?: string | null;
    readonly entityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly entityUuid_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<UserSubGroupWhereInput> | null;
    readonly userUuid?: string | null;
    readonly userUuid_btwn?: ReadonlyArray<string> | null;
    readonly userUuid_gt?: string | null;
    readonly userUuid_gte?: string | null;
    readonly userUuid_in?: ReadonlyArray<string> | null;
    readonly userUuid_lt?: string | null;
    readonly userUuid_lte?: string | null;
    readonly userUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly userUuid_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type UserFacilityWhereInput = {
    readonly AND?: ReadonlyArray<UserFacilityWhereInput> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly facilityUuid?: string | null;
    readonly facilityUuid_btwn?: ReadonlyArray<string> | null;
    readonly facilityUuid_gt?: string | null;
    readonly facilityUuid_gte?: string | null;
    readonly facilityUuid_in?: ReadonlyArray<string> | null;
    readonly facilityUuid_lt?: string | null;
    readonly facilityUuid_lte?: string | null;
    readonly facilityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly facilityUuid_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<UserFacilityWhereInput> | null;
    readonly userUuid?: string | null;
    readonly userUuid_btwn?: ReadonlyArray<string> | null;
    readonly userUuid_gt?: string | null;
    readonly userUuid_gte?: string | null;
    readonly userUuid_in?: ReadonlyArray<string> | null;
    readonly userUuid_lt?: string | null;
    readonly userUuid_lte?: string | null;
    readonly userUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly userUuid_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type route_Home_QueryVariables = {
    readonly cwhere: UserChannelWhereInput;
    readonly gwhere: UserGroupWhereInput;
    readonly swhere: UserSubGroupWhereInput;
    readonly fwhere: UserFacilityWhereInput;
};
export type route_Home_QueryResponse = {
    readonly " $fragmentRefs": Home_query$ref;
};
export type route_Home_Query = {
    readonly response: route_Home_QueryResponse;
    readonly variables: route_Home_QueryVariables;
};



/*
query route_Home_Query(
  $cwhere: UserChannelWhereInput!
  $gwhere: UserGroupWhereInput!
  $swhere: UserSubGroupWhereInput!
  $fwhere: UserFacilityWhereInput!
) {
  ...Home_query
}

fragment Home_query on Query {
  userChannelList(where: $cwhere) {
    channel {
      uuid
      name
      id
    }
    id
  }
  userGroupList(where: $gwhere) {
    group {
      uuid
      name
      logoImageLocation
      id
    }
    id
  }
  userSubGroupList(where: $swhere) {
    subGroup {
      uuid
      name
      logoImageLocation
      id
    }
    id
  }
  userFacilityList(where: $fwhere) {
    facility {
      uuid
      name
      logoImageLocation
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cwhere",
    "type": "UserChannelWhereInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "gwhere",
    "type": "UserGroupWhereInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "swhere",
    "type": "UserSubGroupWhereInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fwhere",
    "type": "UserFacilityWhereInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "logoImageLocation",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_Home_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Home_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_Home_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userChannelList",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "cwhere"
          }
        ],
        "concreteType": "UserChannel",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "channel",
            "storageKey": null,
            "args": null,
            "concreteType": "Channel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userGroupList",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "gwhere"
          }
        ],
        "concreteType": "UserGroup",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "Group",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userSubGroupList",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "swhere"
          }
        ],
        "concreteType": "UserSubGroup",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "SubGroup",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userFacilityList",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "fwhere"
          }
        ],
        "concreteType": "UserFacility",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "facility",
            "storageKey": null,
            "args": null,
            "concreteType": "Facility",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_Home_Query",
    "id": null,
    "text": "query route_Home_Query(\n  $cwhere: UserChannelWhereInput!\n  $gwhere: UserGroupWhereInput!\n  $swhere: UserSubGroupWhereInput!\n  $fwhere: UserFacilityWhereInput!\n) {\n  ...Home_query\n}\n\nfragment Home_query on Query {\n  userChannelList(where: $cwhere) {\n    channel {\n      uuid\n      name\n      id\n    }\n    id\n  }\n  userGroupList(where: $gwhere) {\n    group {\n      uuid\n      name\n      logoImageLocation\n      id\n    }\n    id\n  }\n  userSubGroupList(where: $swhere) {\n    subGroup {\n      uuid\n      name\n      logoImageLocation\n      id\n    }\n    id\n  }\n  userFacilityList(where: $fwhere) {\n    facility {\n      uuid\n      name\n      logoImageLocation\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2323f210dcd2f26aee501b7cd25c417e';
export default node;
