export const isAuthenticated = () => {
  return !!window.localStorage.getItem("pos_apiToken");
};

export const getToken = () => {
  return window.localStorage.getItem("pos_apiToken");
};

export const setToken = (token: string) => {
  window.localStorage.setItem("pos_apiToken", token);
};

export const removeToken = () => {
  window.localStorage.removeItem("pos_apiToken");
};
