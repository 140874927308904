import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./NewGroup").then(module => cb(null, module.default));
  },
  path: "groups/new",
  title: "New Group",
  query: null
};

export const newGroupLocation: RouterToFactory = () => ({
  pathname: `groups/new`
});
