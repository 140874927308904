import * as React from "react";
import Box from "./Box";
import { BoxStyles, BoxChildren } from "./Box";

type VwProps = {
  styles?: BoxStyles;
  children?: BoxChildren;
};

class Vw extends React.Component<VwProps> {
  render() {
    const { styles, children } = this.props;
    return <Box styles={styles}>{children}</Box>;
  }
}

export default Vw;
