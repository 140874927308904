import { createMuiTheme } from "@material-ui/core/styles";
import theme from "./components/themes/theme";

export default createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  overrides: {
    MuiPaper: {
      rounded: { borderRadius: 2 }
    },
    MuiTableCell: {
      head: { fontSize: "1rem" },
      body: { fontSize: "1rem" },
      footer: { fontSize: "1rem" }
    },
    MuiListItem: {
      divider: { borderBottomColor: theme.color.gray200 }
    },
    MuiTooltip: {
      tooltip: { fontSize: "0.85rem", backgroundColor: theme.color.gray900 }
    },
    MuiButtonBase: {
      root: { fontSize: "1rem" }
    },
    MuiButton: {
      root: { fontSize: "1rem" }
    },
    MuiSvgIcon: {
      root: { fontSize: "1.2rem" }
    },
    MuiIcon: {
      root: { fontSize: "1.2rem" }
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        paddingTop: 8,
        lineHeight: "1.6em"
      }
    }
  }
});
