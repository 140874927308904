import baseTheme from "./baseTheme";
import appData from "../../appData";

type Colors = {
  lighter: string;
  normal: string;
  darker: string;
};

export type Theme = {
  typography: any;
  text: {
    bold: string;
    color: string;
    fontFamily: string;
  };
  spacing: { xs: string; s: string; m: string; l: string };
  borderRadius: { s: string; m: string; l: string };
  color: {
    primary: Colors;
    success: Colors;
    info: {
      normal: string;
    };
    danger: {
      lightest: string;
      lighter: string;
      normal: string;
      darker: string;
    };
    gray50: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray800: string;
    gray900: string;
    brand: {
      main: string;
    };
  };
  zIndex: {
    tooltip: number;
    tooltipWrapper: number;
    overlay: number;
    modalOverlay: number;
    progress: number;
    activityIndicator: number;
    alert: number;
  };
  header: { backgroundColor: string; color: string };
};

const theme: Theme = { ...baseTheme };
theme.color.brand.main = appData.primaryColor;
theme.header.backgroundColor = theme.color.brand.main;

export default theme;
