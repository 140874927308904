import graphql from "babel-plugin-relay/macro";

import { startOfDay, endOfDay, subWeeks } from "date-fns";
import { Route, RouterToFactory } from "../../../types";

const path = "/totals/entities";

export const facilityTotalsRoute: Route = {
  getComponent: (_, cb) => {
    import("./FacilityTotals").then(module => cb(null, module.default));
  },
  path,
  title: "Entity Financial Totals",
  query: graphql`
    query route_FacilityTotals_Query(
      $count: Int
      $cursor: String
      $startDateTime: DateTime!
      $endDateTime: DateTime!
    ) {
      facilityConnection(first: $count, after: $cursor) {
        ...FacilityTotals_facilityConnection
      }
    }
  `,
  prepareVariables: props => ({
    count: 100,
    cursor: null,
    find: props.location.query.find || "",
    startDateTime: startOfDay(
      props.location.query.startDate || subWeeks(new Date(), 1)
    ).toISOString(),
    endDateTime: endOfDay(
      props.location.query.endDate || new Date()
    ).toISOString()
  })
};

export const facilityTotalsLocation: RouterToFactory = () => ({
  pathname: path
});
