import * as React from "react";
import { Link } from "react-router";
import { css } from "emotion";
import { dimensions } from "../../../constants";
import theme from "../../themes/theme";
import appData from "../../../appData";
import styles from "./Header.module.css";

type Props = { children?: React.ReactNode };

const Header = (props: Props) => (
  <header
    className={
      css({
        // TODO - move everything to CSS
        height: dimensions.header.height,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: theme.color.gray200
      }) +
      " " +
      styles.root
    }
  >
    <Link
      to="/"
      className={css({
        color: "white",
        fontWeight: 700,
        textDecoration: "none",
        lineHeight: 1
      })}
    >
      <img src={appData.logoURL} alt={appData.displayName} height="30px" />
    </Link>
    {props.children}
  </header>
);

export default Header;
