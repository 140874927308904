import { Route, RouterToFactory } from "../../../types";

export const emailRoute: Route = {
  getComponent: (_, cb) => {
    import("./Email").then(module => cb(null, module.default));
  },
  path: "*/email",
  title: "Email"
};

export const emailLocation: RouterToFactory = () => ({ pathname: "/*/email" });
