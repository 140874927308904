import { isAuthenticated } from "./util/auth";
import { loginLocation } from "./components/screens/Login/route";
import { notFoundRoute } from "./components/screens/NotFound/route";
import { loginRoute } from "./components/screens/Login/route";
import { route as homeRoute } from "./components/screens/Home/route";
import { editAmountRoute } from "./components/screens/EditAmount/route";
import { facilityTotalsRoute } from "./components/screens/FacilityTotals/route";
import { addFacilityRoute } from "./components/screens/AddFacility/route";
import { staffRoute } from "./components/screens/Staff/route";
import { emailRoute } from "./components/screens/Email/route";
import { route as channelRoute } from "./components/screens/Channel/route";
import { route as newGroupRoute } from "./components/screens/NewGroup/route";
import { route as groupRoute } from "./components/screens/Group/route";
import { route as newSubGroupRoute } from "./components/screens/NewSubGroup/route";
import { route as subGroupRoute } from "./components/screens/SubGroup/route";
import { route as facilitiesRoute } from "./components/screens/Facilities/route";
import { route as newFacilityRoute } from "./components/screens/NewFacility/route";
import { route as facilityRoute } from "./components/screens/Facility/route";
// import { route as membersRoute } from "./components/screens/Members/route";
// import { route as accessRoute } from "./components/screens/Access/route";
import { userRoute } from "./components/screens/User/route";
import { route as userPermissionsRoute } from "./components/screens/UserPermissions/route";
import { settingProfileRoute } from "./components/screens/SettingsProfile/route";
import { settingAccountRoute } from "./components/screens/SettingsAccount/route";
import { route as marketplaceRoute } from "./components/screens/marketplace/route";

export default [
  loginRoute,
  emailRoute,
  {
    onEnter: (nextState, replace) => {
      if (!isAuthenticated()) {
        replace(loginLocation(nextState.location));
      }
    },
    indexRoute: homeRoute,
    childRoutes: [
      channelRoute,
      newGroupRoute,
      groupRoute,
      newSubGroupRoute,
      subGroupRoute,
      newFacilityRoute,
      facilityRoute,
      facilitiesRoute,
      facilityTotalsRoute,
      // membersRoute,
      // accessRoute,
      userRoute,
      userPermissionsRoute,
      settingProfileRoute,
      settingAccountRoute,
      addFacilityRoute,
      staffRoute,
      editAmountRoute,
      marketplaceRoute
    ]
  },
  notFoundRoute
];
