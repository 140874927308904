import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./UserPermissions").then(module => cb(null, module.default));
  },
  path: "/users/:userUuid/permissions",
  title: "User Permissions",
  query: graphql`
    query route_UserPermissions_Query($uuid: ID!) {
      ...UserPermissions_query
    }
  `,
  prepareVariables: props => ({
    uuid: props.params.userUuid
  })
};

export const userPermissionsLocation = (uuid: string) => ({
  pathname: `/users/${uuid}/permissions`
});
