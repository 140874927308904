import { Route } from "../../../types";

export const settingAccountRoute: Route = {
  getComponent: (_, cb) => {
    import("./SettingsAccount").then(module => cb(null, module.default));
  },
  path: "/settings/account",
  title: "Account",
  query: null
};

export const settingsAccountLocation = () => ({
  pathname: "/settings/account"
});
