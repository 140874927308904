import * as React from "react";
import { Link } from "react-router";
import { css } from "emotion";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../themes/theme";

const ID = "m-menu";

export default function MarketplaceDropdown() {
  const [isOpen, set_isOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement>(null);

  const handleToggle = () => {
    set_isOpen(!isOpen);
  };

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    set_isOpen(false);
  };

  const h = window.location.origin.replace("admin", "marketplace");

  return (
    <>
      <Button
        buttonRef={anchorEl}
        aria-owns={isOpen ? ID : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple={true}
        style={{
          backgroundColor: isOpen
            ? theme.header.color
            : theme.header.backgroundColor,
          color: isOpen ? theme.header.backgroundColor : theme.header.color,
          fontWeight: 600,
          textTransform: "none",
          paddingLeft: 8,
          paddingRight: 8,
          flexShrink: 0
        }}
      >
        Marketplace
        <ExpandMoreIcon
          style={{
            color: isOpen ? theme.header.backgroundColor : theme.header.color,
            marginLeft: 2
          }}
        />
      </Button>
      <Popper
        open={isOpen}
        anchorEl={anchorEl.current}
        transition={true}
        disablePortal={true}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Grow {...TransitionProps} id={ID} style={{ marginTop: 5 }}>
            <Paper style={{ maxHeight: 48 * 10, overflowY: "scroll" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {[
                    {
                      kind: "external",
                      href: h,
                      label: "Marketplace site"
                    },
                    {
                      kind: "external",
                      href: `${h}/procedures`,
                      label: "Marketplace procedures"
                    },
                    {
                      kind: "external",
                      href: `${h}/physicians`,
                      label: "Marketplace physicians"
                    },
                    {
                      kind: "external",
                      href: `${h}/facilities`,
                      label: "Marketplace facilities"
                    },
                    {
                      kind: "internal",
                      href: "/marketplace",
                      label: "Marketplace settings"
                    }
                  ].map(x =>
                    x.kind === "external" ? (
                      <MenuItem
                        onClick={handleClose}
                        component={({ children, ...rest }) => (
                          <a
                            {...rest}
                            href={x.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children}
                          </a>
                        )}
                        className={css({ textDecoration: "none" })}
                      >
                        {x.label}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={handleClose}
                        component={({ children, ...rest }) => (
                          <Link {...rest} to={x.href}>
                            {children}
                          </Link>
                        )}
                        className={css({ textDecoration: "none" })}
                      >
                        {x.label}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
