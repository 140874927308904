import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const settingProfileRoute: Route = {
  getComponent: (_, cb) => {
    import("./SettingsProfile").then(module => cb(null, module.default));
  },
  path: "/settings/profile",
  title: "Profile",
  query: graphql`
    query route_SettingsProfile_Query {
      viewer {
        ...SettingsProfile_viewer
      }
    }
  `
};

export const settingsProfileLocation = () => ({
  pathname: "/settings/profile"
});
