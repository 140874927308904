import * as React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Link } from "react-router";
import { css } from "emotion";
import getUserUuid from "../../../util/getUserUuid";
import { RelayEnvironmentContext } from "../../../RelayEnvironmentContext";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../themes/theme";
import { userLocation } from "../../screens/User/route";
import { settingsProfileLocation } from "../../screens/SettingsProfile/route";
import { AppContext } from "../../../AppContext";
import { UserDropdown_Query } from "../../../__generated__/UserDropdown_Query.graphql";

const ID = "u-menu";

export default function UserDropdown() {
  const { relay_environment } = React.useContext(RelayEnvironmentContext);
  const { handleLogout } = React.useContext(AppContext);
  const [isOpen, set_isOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement>(null);

  const handleToggle = () => {
    set_isOpen(!isOpen);
  };

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    set_isOpen(false);
  };

  return (
    <QueryRenderer<UserDropdown_Query>
      environment={relay_environment}
      query={graphql`
        query UserDropdown_Query {
          viewer {
            name
          }
        }
      `}
      variables={{}}
      render={rs => {
        return (
          <>
            <Button
              buttonRef={anchorEl}
              aria-owns={isOpen ? ID : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disableRipple={true}
              style={{
                backgroundColor: isOpen
                  ? theme.header.color
                  : theme.header.backgroundColor,
                color: isOpen
                  ? theme.header.backgroundColor
                  : theme.header.color,
                fontWeight: 600,
                textTransform: "none",
                paddingLeft: 8,
                paddingRight: 8,
                flexShrink: 0
              }}
            >
              {rs.error
                ? "Account"
                : // To avoid flash of "Account", set to empty string while loading.
                  (rs.props && rs.props.viewer && rs.props.viewer.name) || ""}
              <ExpandMoreIcon
                style={{
                  color: isOpen
                    ? theme.header.backgroundColor
                    : theme.header.color,
                  marginLeft: 2
                }}
              />
            </Button>
            <Popper
              open={isOpen}
              anchorEl={anchorEl.current}
              transition={true}
              disablePortal={true}
              placement="bottom-end"
            >
              {({ TransitionProps }) => (
                // @ts-ignore
                <Grow {...TransitionProps} id={ID} style={{ marginTop: 5 }}>
                  <Paper style={{ width: 160 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        {[
                          { to: userLocation(getUserUuid()), text: "Profile" },
                          { to: settingsProfileLocation(), text: "Settings" }
                        ].map(({ to, text }) => (
                          // @ts-ignore
                          <MenuItem
                            onClick={handleClose}
                            component={Link}
                            to={to}
                            className={css({ textDecoration: "none" })}
                          >
                            {text}
                          </MenuItem>
                        ))}

                        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        );
      }}
    />
  );
}
