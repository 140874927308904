import jwtDecode from "jwt-decode";
import { getToken } from "./auth";

export default function getUserUuid() {
  const token = getToken();
  try {
    const { sub } = jwtDecode(token);
    return sub || null;
  } catch (e) {
    return null;
  }
}
