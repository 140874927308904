import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router, useRouterHistory } from "react-router";
import { createHistory } from "history";
import { QueryRenderer } from "react-relay";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { css } from "emotion";
import { setDocumentTitle } from "./util/documentTitle";
import { removeToken, setToken } from "./util/auth";
import routes from "./routes";
import App from "./components/App";
import Vw from "./components/lib/Vw";
import Txt from "./components/lib/Txt";
import H1 from "./components/lab/H1";
import ActivityIndicator from "./components/lib/ActivityIndicator";
import { loginLocation } from "./components/screens/Login/route";
import muiTheme from "./muiTheme";
import { createRelayEnvironment } from "./createRelayEnvironment";
import { AppContext } from "./AppContext";
import { RelayEnvironmentContext } from "./RelayEnvironmentContext";
import { REACT_APP_BASENAME } from "./config";
import * as serviceWorker from "./serviceWorker";
import "react-virtualized/styles.css";
import "@reach/dialog/styles.css";
import "@reach/menu-button/styles.css";

const history = useRouterHistory(createHistory)({
  basename: REACT_APP_BASENAME
});

type RootProps = {};

type RootState = { relayEnvironment: any };

class Root extends React.Component<RootProps, RootState> {
  constructor(props) {
    super(props);
    this.state = {
      relayEnvironment: createRelayEnvironment(this.handleLogout)
    };
  }

  // We need to reset the environment once we get the token.
  handleLogin = (token: string, cb?: () => void) => {
    setToken(token);
    this.setState(
      { relayEnvironment: createRelayEnvironment(this.handleLogout) },
      cb
    );
  };

  handleLogout = () => {
    removeToken();
    // We don't have access to the router because Root renders it.
    // We don't need to call createEnvironment to create a new
    // environment because we're doing a full refresh.

    // `loginLocation().pathname` already includes a leading slash.
    const basename = REACT_APP_BASENAME === "/" ? "" : REACT_APP_BASENAME;
    // @ts-ignore
    window.location =
      window.location.origin + basename + loginLocation().pathname;
    // this.setState({
    //   relayEnvironment: createRelayEnvironment(this.handleLogout),
    // });
  };

  rerenderRoot = () => {
    this.forceUpdate();
  };

  onEnter = (nextState, replace) => {
    setDocumentTitle(
      nextState.routes && last(nextState.routes) && last(nextState.routes).title
    );
  };

  renderError = error => {
    return (
      <Vw
        styles={{
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          height: "100%"
        }}
      >
        <H1 styles={{ fontSize: 36, paddingBottom: 32 }}>
          {isAuthorizationError(error) ? "Forbidden" : "Oops!"}
        </H1>
        <Txt>
          {// FIXME: There's probably a better check than this.
          isAuthorizationError(error)
            ? "You don't have permission to view this content."
            : error instanceof TypeError
            ? "We couldn't connect. Please check your network connection."
            : "Something went wrong. Please try again."}
        </Txt>
      </Vw>
    );
  };

  renderApp = props => {
    return (
      <App {...props} environment={this.state.relayEnvironment}>
        {props.children}
      </App>
    );
  };

  createElement = (Component, props) => {
    const hasQuery = Object.prototype.hasOwnProperty.call(props.route, "query");
    return hasQuery ? (
      <QueryRenderer
        environment={this.state.relayEnvironment}
        query={props.route.query || undefined}
        variables={
          props.route.prepareVariables
            ? props.route.prepareVariables(props)
            : {}
        }
        render={({ error, props: relayProps }) => {
          if (error) {
            return this.renderError(error);
          } else if (relayProps) {
            return (
              <Component
                {...props}
                // TODO - this is gross, but a lot of screens assume a
                // rerender for the UI to remain consistent after e.g. an
                // add or delete to a list. See if this can be removed.
                key={Math.random()}
                rerenderRoot={this.rerenderRoot}
                {...relayProps}
                query={relayProps}
              />
            );
          } else {
            return (
              <div
                className={css({
                  position: "fixed",
                  top: "50%",
                  left: "50%"
                })}
              >
                <ActivityIndicator kind="dark" size="m" />
              </div>
            );
          }
        }}
      />
    ) : (
      <Component
        {...props}
        environment={this.state.relayEnvironment}
        handleLogin={this.handleLogin}
      />
    );
  };

  render() {
    return (
      <RelayEnvironmentContext.Provider
        value={{
          relay_environment: this.state.relayEnvironment,
          // TODO
          set_relay_environment: () => {}
        }}
      >
        <MuiThemeProvider theme={muiTheme}>
          <AppContext.Provider
            value={{
              handleLogin: this.handleLogin,
              handleLogout: this.handleLogout
            }}
          >
            <Router
              history={history}
              routes={{
                path: "/",
                component: this.renderApp,
                onEnter: this.onEnter,
                childRoutes: routes
              }}
              createElement={this.createElement}
            />
          </AppContext.Provider>
        </MuiThemeProvider>
      </RelayEnvironmentContext.Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();

// Ugh.
function isAuthorizationError(error) {
  return (
    error.source &&
    error.source.errors &&
    !!error.source.errors.find(({ message }) => message === "Not authorized")
  );
}

function last(xs) {
  return xs[xs.length - 1];
}
