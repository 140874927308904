/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FacilityTotals_facilityConnection$ref } from "./FacilityTotals_facilityConnection.graphql";
export type route_FacilityTotals_QueryVariables = {
    readonly count?: number | null;
    readonly cursor?: string | null;
    readonly startDateTime: any;
    readonly endDateTime: any;
};
export type route_FacilityTotals_QueryResponse = {
    readonly facilityConnection: {
        readonly " $fragmentRefs": FacilityTotals_facilityConnection$ref;
    } | null;
};
export type route_FacilityTotals_Query = {
    readonly response: route_FacilityTotals_QueryResponse;
    readonly variables: route_FacilityTotals_QueryVariables;
};



/*
query route_FacilityTotals_Query(
  $count: Int
  $cursor: String
  $startDateTime: DateTime!
  $endDateTime: DateTime!
) {
  facilityConnection(first: $count, after: $cursor) {
    ...FacilityTotals_facilityConnection
  }
}

fragment FacilityTotals_facilityConnection on FacilityConnection {
  edges {
    node {
      name
      financialAmountsByPaymentMethod(startDateTime: $startDateTime, endDateTime: $endDateTime) {
        creditAmount
        achAmount
        swipeAmount
        cashAmount
        aggregateAmount
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDateTime",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDateTime",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_FacilityTotals_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "facilityConnection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FacilityConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FacilityTotals_facilityConnection",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_FacilityTotals_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "facilityConnection",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "FacilityConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FacilityEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Facility",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "financialAmountsByPaymentMethod",
                    "storageKey": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "endDateTime",
                        "variableName": "endDateTime"
                      },
                      {
                        "kind": "Variable",
                        "name": "startDateTime",
                        "variableName": "startDateTime"
                      }
                    ],
                    "concreteType": "FinancialAmountsByPaymentMethod",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "creditAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "achAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "swipeAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cashAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "aggregateAmount",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_FacilityTotals_Query",
    "id": null,
    "text": "query route_FacilityTotals_Query(\n  $count: Int\n  $cursor: String\n  $startDateTime: DateTime!\n  $endDateTime: DateTime!\n) {\n  facilityConnection(first: $count, after: $cursor) {\n    ...FacilityTotals_facilityConnection\n  }\n}\n\nfragment FacilityTotals_facilityConnection on FacilityConnection {\n  edges {\n    node {\n      name\n      financialAmountsByPaymentMethod(startDateTime: $startDateTime, endDateTime: $endDateTime) {\n        creditAmount\n        achAmount\n        swipeAmount\n        cashAmount\n        aggregateAmount\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3de963fa05bf04d680d35b3a418eb544';
export default node;
