import { Route, RouterToFactory } from "../../../types";

export const editAmountRoute: Route = {
  getComponent: (_, cb) => {
    import("./EditAmount").then(module => cb(null, module.default));
  },
  path: "*/edit",
  title: "Edit Amount"
};

export const editAmountLocation: RouterToFactory = (prefix, state) => ({
  pathname: `/${prefix}/edit`,
  state
});
