/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserDropdown_QueryVariables = {};
export type UserDropdown_QueryResponse = {
    readonly viewer: {
        readonly name: string | null;
    } | null;
};
export type UserDropdown_Query = {
    readonly response: UserDropdown_QueryResponse;
    readonly variables: UserDropdown_QueryVariables;
};



/*
query UserDropdown_Query {
  viewer {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserDropdown_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserDropdown_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserDropdown_Query",
    "id": null,
    "text": "query UserDropdown_Query {\n  viewer {\n    name\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8324f7698ad114c928598fb01521c839';
export default node;
