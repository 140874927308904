import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const route: Route = {
  path: "channel",
  getComponent: (_, cb) => {
    import("./Channel").then(module => cb(null, module.default));
  },
  title: "Channel",
  query: graphql`
    query route_channel_Query {
      channel {
        ...Channel_channel
      }
    }
  `
};

export const channelLocation = () => ({
  pathname: "channel"
});
