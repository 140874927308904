import graphql from "babel-plugin-relay/macro";
import { Route } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./SubGroup").then(module => cb(null, module.default));
  },
  path: "sub-groups/:subGroupUuid",
  title: "Sub Group",
  query: graphql`
    query route_SubGroup_Query($uuid: ID!) {
      ...SubGroup_query
    }
  `,
  prepareVariables: props => ({
    uuid: props.params.subGroupUuid
  }),

  getIndexRoute: (_, cb) => {
    import("../Facilities/route").then(module => cb(null, module.route));
  },
  getChildRoutes: (_, cb) => {
    Promise.all([
      // import("../Members/route").then(module => module.route),
      import("../SubGroupSettings/route").then(module => module.route)
    ]).then(routes => cb(null, routes));
  }
};

export const subGroupLocation = (uuid: string) => ({
  pathname: `sub-groups/${uuid}`
});
