/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserGroupWhereInput = {
    readonly AND?: ReadonlyArray<UserGroupWhereInput> | null;
    readonly created?: any | null;
    readonly created_btwn?: ReadonlyArray<any> | null;
    readonly created_gt?: any | null;
    readonly created_gte?: any | null;
    readonly created_in?: ReadonlyArray<any> | null;
    readonly created_lt?: any | null;
    readonly created_lte?: any | null;
    readonly created_nbtwn?: ReadonlyArray<any> | null;
    readonly created_nin?: ReadonlyArray<any> | null;
    readonly creator?: string | null;
    readonly creator_btwn?: ReadonlyArray<string> | null;
    readonly creator_gt?: string | null;
    readonly creator_gte?: string | null;
    readonly creator_in?: ReadonlyArray<string> | null;
    readonly creator_lt?: string | null;
    readonly creator_lte?: string | null;
    readonly creator_nbtwn?: ReadonlyArray<string> | null;
    readonly creator_nin?: ReadonlyArray<string> | null;
    readonly entityUuid?: string | null;
    readonly entityUuid_btwn?: ReadonlyArray<string> | null;
    readonly entityUuid_gt?: string | null;
    readonly entityUuid_gte?: string | null;
    readonly entityUuid_in?: ReadonlyArray<string> | null;
    readonly entityUuid_lt?: string | null;
    readonly entityUuid_lte?: string | null;
    readonly entityUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly entityUuid_nin?: ReadonlyArray<string> | null;
    readonly OR?: ReadonlyArray<UserGroupWhereInput> | null;
    readonly userUuid?: string | null;
    readonly userUuid_btwn?: ReadonlyArray<string> | null;
    readonly userUuid_gt?: string | null;
    readonly userUuid_gte?: string | null;
    readonly userUuid_in?: ReadonlyArray<string> | null;
    readonly userUuid_lt?: string | null;
    readonly userUuid_lte?: string | null;
    readonly userUuid_nbtwn?: ReadonlyArray<string> | null;
    readonly userUuid_nin?: ReadonlyArray<string> | null;
    readonly uuid?: string | null;
    readonly uuid_btwn?: ReadonlyArray<string> | null;
    readonly uuid_gt?: string | null;
    readonly uuid_gte?: string | null;
    readonly uuid_in?: ReadonlyArray<string> | null;
    readonly uuid_lt?: string | null;
    readonly uuid_lte?: string | null;
    readonly uuid_nbtwn?: ReadonlyArray<string> | null;
    readonly uuid_nin?: ReadonlyArray<string> | null;
};
export type GroupDropdown_QueryVariables = {
    readonly where: UserGroupWhereInput;
};
export type GroupDropdown_QueryResponse = {
    readonly userGroupList: ReadonlyArray<{
        readonly group: {
            readonly uuid: string;
            readonly name: string | null;
            readonly logoImageLocation: string | null;
        } | null;
    } | null> | null;
};
export type GroupDropdown_Query = {
    readonly response: GroupDropdown_QueryResponse;
    readonly variables: GroupDropdown_QueryVariables;
};



/*
query GroupDropdown_Query(
  $where: UserGroupWhereInput!
) {
  userGroupList(where: $where) {
    group {
      uuid
      name
      logoImageLocation
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "where",
    "type": "UserGroupWhereInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logoImageLocation",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GroupDropdown_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userGroupList",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserGroup",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "Group",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GroupDropdown_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userGroupList",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UserGroup",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "Group",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "GroupDropdown_Query",
    "id": null,
    "text": "query GroupDropdown_Query(\n  $where: UserGroupWhereInput!\n) {\n  userGroupList(where: $where) {\n    group {\n      uuid\n      name\n      logoImageLocation\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '426da40001047858b829aa342bc2c842';
export default node;
