import graphql from "babel-plugin-relay/macro";

import getUserUuid from "../../../util/getUserUuid";
import { Route, RouterToFactory } from "../../../types";

export const route: Route = {
  getComponent: (_, cb) => {
    import("./Home").then(module => cb(null, module.default));
  },
  path: "/",
  title: "Home",
  query: graphql`
    query route_Home_Query(
      $cwhere: UserChannelWhereInput!
      $gwhere: UserGroupWhereInput!
      $swhere: UserSubGroupWhereInput!
      $fwhere: UserFacilityWhereInput!
    ) {
      ...Home_query
    }
  `,
  prepareVariables: props => {
    const where = { userUuid: getUserUuid() };
    return {
      cwhere: where,
      gwhere: where,
      swhere: where,
      fwhere: where
    };
  }
};

export const homeLocation: RouterToFactory = () => ({
  pathname: "/"
});
