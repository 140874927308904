/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { NewFacility_entityLocationTypeList$ref } from "./NewFacility_entityLocationTypeList.graphql";
import { NewFacility_subGroup$ref } from "./NewFacility_subGroup.graphql";
import { NewFacility_subGroupList$ref } from "./NewFacility_subGroupList.graphql";
export type route_NewFacility_QueryVariables = {
    readonly subGroupUuid: string;
};
export type route_NewFacility_QueryResponse = {
    readonly subGroup: {
        readonly " $fragmentRefs": NewFacility_subGroup$ref;
    } | null;
    readonly subGroupList: ReadonlyArray<{
        readonly " $fragmentRefs": NewFacility_subGroupList$ref;
    } | null> | null;
    readonly entityLocationTypeList: ReadonlyArray<{
        readonly " $fragmentRefs": NewFacility_entityLocationTypeList$ref;
    } | null> | null;
};
export type route_NewFacility_Query = {
    readonly response: route_NewFacility_QueryResponse;
    readonly variables: route_NewFacility_QueryVariables;
};



/*
query route_NewFacility_Query(
  $subGroupUuid: ID!
) {
  subGroup(uuid: $subGroupUuid) {
    ...NewFacility_subGroup
    id
  }
  subGroupList {
    ...NewFacility_subGroupList
    id
  }
  entityLocationTypeList {
    ...NewFacility_entityLocationTypeList
    id
  }
}

fragment NewFacility_subGroup on SubGroup {
  uuid
}

fragment NewFacility_subGroupList on SubGroup {
  uuid
  name
}

fragment NewFacility_entityLocationTypeList on EntityLocationType {
  uuid
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "subGroupUuid",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "subGroupUuid"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uuid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "route_NewFacility_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SubGroup",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewFacility_subGroup",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroupList",
        "storageKey": null,
        "args": null,
        "concreteType": "SubGroup",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewFacility_subGroupList",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "entityLocationTypeList",
        "storageKey": null,
        "args": null,
        "concreteType": "EntityLocationType",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NewFacility_entityLocationTypeList",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "route_NewFacility_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SubGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subGroupList",
        "storageKey": null,
        "args": null,
        "concreteType": "SubGroup",
        "plural": true,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "entityLocationTypeList",
        "storageKey": null,
        "args": null,
        "concreteType": "EntityLocationType",
        "plural": true,
        "selections": (v4/*: any*/)
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "route_NewFacility_Query",
    "id": null,
    "text": "query route_NewFacility_Query(\n  $subGroupUuid: ID!\n) {\n  subGroup(uuid: $subGroupUuid) {\n    ...NewFacility_subGroup\n    id\n  }\n  subGroupList {\n    ...NewFacility_subGroupList\n    id\n  }\n  entityLocationTypeList {\n    ...NewFacility_entityLocationTypeList\n    id\n  }\n}\n\nfragment NewFacility_subGroup on SubGroup {\n  uuid\n}\n\nfragment NewFacility_subGroupList on SubGroup {\n  uuid\n  name\n}\n\nfragment NewFacility_entityLocationTypeList on EntityLocationType {\n  uuid\n  name\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '47f3ea39743494be413633f12c7f0e96';
export default node;
